import React from "react"
import MainImage from "./MainImage"
import PortableText from "./portableText"
import { PlayerTable } from "./PlayerTable"
const serializers = {
	types: {
		authorReference: ({ node }) => <span>{node.author.name}</span>,
		// mainImage: Figure
		mainImage: ({ node }) => <MainImage mainImage={node} />,
		playerTable: ({ node: { tableType, players } }) => <PlayerTable tableType={tableType} players={players} />,
		textContent: ({ node }) => <PortableText blocks={node.content} />
	}
}

export default serializers
