import React from "react"
import styled from "styled-components"
import { Box, Heading } from "../ziibo"
const StyledPlayerTable = styled.table`
	border-collapse: collapse;
	width: 100%;

	border: 2px solid #132448;

	thead {
		background: #132448;
	}

	thead {
		color: white;
	}

	th,
	td {
		text-align: center;
		padding: 5px 0;
	}

	tbody tr:nth-child(even) {
		background: #ecf0f1;
	}

	tbody tr:hover {
		background: #bdc3c7;
		color: #ffffff;
	}
`
const LineupTable = ({ players }) => {
	return (
		<Box mb={5}>
			<Heading as="h5" fontSize={500}>
				Projected Opening Day Starting Lineup
			</Heading>
			<StyledPlayerTable>
				<thead>
					<td>Order</td>
					<td>Player</td>
					<td>Position</td>
					<td>Bats</td>
				</thead>
				<tbody>
					{players.map((player, index) => {
						return (
							<tr key={player.name}>
								<td>{index + 1}</td>
								<td>
									<a href={player.link} style={{ color: "#434875" }}>
										{player.name}
									</a>
								</td>
								<td>{player.position}</td>
								<td>{player.bats}</td>
							</tr>
						)
					})}
				</tbody>
			</StyledPlayerTable>
		</Box>
	)
}

const RotationTable = ({ players }) => {
	return (
		<Box mb={5}>
			<Heading as="h5" fontSize={500}>
				Projected Opening Day Rotation
			</Heading>
			<StyledPlayerTable>
				<thead>
					<td>Role</td>
					<td>Player</td>
					<td>Throw</td>
				</thead>
				<tbody>
					{players.map((player, index) => {
						return (
							<tr key={player.name}>
								<td>
									{player.position}
									{index + 1}
								</td>
								<td>
									{" "}
									<a href={player.link} style={{ color: "#434875" }}>
										{player.name}
									</a>
								</td>
								<td>{player.throws}</td>
							</tr>
						)
					})}
				</tbody>
			</StyledPlayerTable>
		</Box>
	)
}

const BullpenTable = ({ players }) => {
	return (
		<Box mb={5}>
			<Heading as="h5" fontSize={500}>
				Projected Opening Day Bullpen
			</Heading>
			<StyledPlayerTable>
				<thead>
					<td>Role</td>
					<td>Player</td>
					<td>Throws</td>
				</thead>
				<tbody>
					{players.map((player) => {
						return (
							<tr key={player.name}>
								<td>{player.position}</td>
								<td>
									{" "}
									<a href={player.link} style={{ color: "#434875" }}>
										{player.name}
									</a>
								</td>
								<td>{player.throws}</td>
							</tr>
						)
					})}
				</tbody>
			</StyledPlayerTable>
		</Box>
	)
}
const PlayerTable = ({ players, tableType }) => {
	return (
		<>
			{tableType === "Lineup" && <LineupTable players={players} />}
			{tableType === "Rotation" && <RotationTable players={players} />}
			{tableType === "Bullpen" && <BullpenTable players={players} />}
		</>
	)
}

export { PlayerTable }
