import React from "react"
import Img from "gatsby-image"

// const MainImage = ({ mainImage, width = 1200 }) => {
// 	const imgUrl =
// 		mainImage &&
// 		imageUrlFor(buildImageObj(mainImage))
// 			.width(width)
// 			.height(Math.floor((9 / 16) * width))
// 			.fit("crop")
// 			.auto("format")
// 			.url()

// 	return imgUrl ? <img src={imgUrl} alt={mainImage.alt || ""} style={{ maxWidth: "100%", margin: "24px 0" }} /> : <></>
// }

const MainImage = ({ mainImage }) => {
	return <Img fluid={mainImage.asset.fluid} alt={mainImage.alt} style={{ position: "initial" }} imgStyle={{ maxWidth: "none" }} />
}
export default MainImage
